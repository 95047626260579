import React from "react"
import { Button } from 'reactstrap'
import logo from '../images/Logo-pinv.png'
import { Link } from 'react-router-dom'

const NotFound = () => (
  <section>
    <div className="cont-100vh">
      <div className="container">
        <div className="cont-grey u-center-center">
          <img src={logo} className="u-center-horizontal" alt="" />
          <p>
            Risorsa non trovata
          </p>

          <Link to="/" replace={true}>
            <Button color="primary" className="u-center-horizontal btn-pinv">
              vai alla HOME
            </Button>
          </Link>

        </div>
      </div>
    </div>
  </section>
)

export default NotFound;
