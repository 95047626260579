import React from 'react'

const NoClientsMessage = () => (
	<div className="container">
		<div className="box-invoces">
			<div className="head">
				<h5>Invita i tuoi clienti ad utilizzare PINV</h5>
			</div>
			<div className="body body-no-clients">
				<h4>Questi i vantaggi per il tuo studio:</h4>
				<ul>
					<li>Documenti dei clienti in tempo reale</li>
					<li>Download dei file PDF</li>
					<li>Download dei file XML per le fatture elettroniche</li>
					<li>Download del file CSV per importazione / esportazione</li>
				</ul>
				<h4>Questi i vantaggi per i tuoi clienti:</h4>
				<ul>
					<li>Interfaccia grafica semplice</li>
					<li>Costi contenuti</li>
					<li>Nessuna installazione, tutto in cloud</li>
					<li>Sicuro: dati e comunicazione sono cifrati</li>
				</ul>
				<br />
				<p>
					Contattaci per maggiori informazioni <a href="mailto:hello@pinv.it">hello@pinv.it</a>
				</p>
			</div>
		</div>
	</div>
)

export default NoClientsMessage