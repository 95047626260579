import React from 'react';
import moment from 'moment';
import { StyledDownloadCsvButton } from './components/style/Components';

const DownloadMovementsButton = ({ movements }) => {
  const [csvData, setCsvData] = React.useState({ data: [], headers: [] });
  const [loading, setLoading] = React.useState(false);

  const downloadCSV = () => {
    if (!loading) {
      setLoading(true);

      const data = movements.map((movement) => ({
        date: moment(movement.date).toISOString(),
        causale: movement.causale,
        dare: movement.tipo === 'outcome' ? `-${movement.amount}` : 0,
        avere: movement.tipo === 'income' ? `+${movement.amount}` : 0,
        currency: movement.currency,
      }));
  
      const headers = [
        { label: 'Data', key: 'date' },
        { label: 'Descrizione', key: 'causale' },
        { label: 'Dare', key: 'dare' },
        { label: 'Avere', key: 'avere' },
        { label: 'Valuta', key: 'currency' },
      ];

      setCsvData({ data, headers });
      setLoading(false);
    }
  };

  return (
    <>
        <StyledDownloadCsvButton
          filename={`movements-${moment().format('YYYY-MM-DD')}.csv`}
          data={csvData.data}
          headers={csvData.headers}
          asyncOnClick={true}
          onClick={downloadCSV}
        >
          {loading ? 'File csv in creazione ...' : 'Scarica movimenti'}
        </StyledDownloadCsvButton>
    </>
  );
};

export default DownloadMovementsButton;
