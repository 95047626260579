import React from 'react'
import { Modal } from 'reactstrap';
import Loading from './Loading';

const LoadingAllScreen = ({ open = false }) => {

  return (
    <Modal
      isOpen={open}
      centered={true}
      backdrop={true}
      fade={false}
      backdropClassName="loading-modal-backdrop"
      contentClassName="loading-modal-content"
    >
      <Loading color="green" />
    </Modal>
  )
}

export default LoadingAllScreen