/* eslint-disable */
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { StyledButton } from '../Dashboard/components/style/Components'
import { DropzoneBox } from './style/Components'

function getExtension(filename = '') {
  const extension = filename.split('.').pop() || ''
  return extension.toLowerCase()
}

function readFile(file) {
  const { name: filename = '', path = '', type = '', size = 0 } = file
  const extension = getExtension(filename)

  const response = {
    filename,
    path,
    type,
    size,
  }

  return new Promise((resolve) => {
    if (extension === 'xml' && extension === 'p7m') {
      return resolve({
        ...response,
        error: `Il formato del file ${filename} non è supportato`,
      })
    }

    const reader = new FileReader()

    reader.onabort = () =>
      resolve({
        ...response,
        error: `La lettura del file ${filename} è annullata`,
      })

    reader.onerror = () =>
      resolve({
        ...response,
        error: `La lettura del file ${filename} è fallita`,
      })

    reader.onload = () => {
      const buffer = reader.result

      let binary = ''
      const bytes = new Uint8Array(buffer)
      const len = bytes.byteLength

      for (let i = 0; i < len; i += 1) {
        binary += String.fromCharCode(bytes[i])
      }

      resolve({
        ...response,
        file: window.btoa(binary),
      })
    }

    reader.readAsArrayBuffer(file)
  })
}

export default ({ onReadFiles }) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    const files = await Promise.all(
      acceptedFiles.map(async (file) => {
        const response = await readFile(file)
        return response
      }),
    )

    onReadFiles(files)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <DropzoneBox {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <h3>Rilascia i file qui...</h3>
      ) : (
        <>
          <h3>Carica qui i file XML o P7M da importare</h3>
          <StyledButton>carica</StyledButton>
        </>
      )}
    </DropzoneBox>
  )
}
