import React from 'react';
import moment from 'moment';
import { formatCurrency } from '../helpers/currency';
import { ReactComponent as Income } from '../../../images/incassi-previsti.svg';
import { ReactComponent as Outcome } from '../../../images/pagamenti-previsti.svg';
import styles from './style/HomeSection.module.css';

const KpiCard = ({ loading, date, incomingSubject, incomingValue, outgoingSubject, outgoingValue }) => {

  return (
    <div className={styles.cardPrimary}>
      <div className={styles.kpiDate}>{`Al ${moment(date).format('DD/MM/YYYY')}`}</div>
      {incomingSubject && (
        <>
          <div className={styles.subject}>{incomingSubject}</div>
          <div className={styles.kpiContainer}>
            <Income className={styles.incomeSvg} />
            <div className={styles.kpiValue}>{loading ? '...' : formatCurrency(incomingValue)}</div>
          </div>
        </>
      )}
      {outgoingSubject && (
        <>
          <div className={styles.subject}>{outgoingSubject}</div>
          <div className={styles.kpiContainer}>
            <Outcome className={styles.outcomeSvg} />
            <div className={styles.kpiValue}>{loading ? '...' : formatCurrency(outgoingValue)}</div>
          </div>
        </>
      )}
    </div>
  );
};
export default KpiCard;
