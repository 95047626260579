import React from 'react'
import { Button } from 'reactstrap'
import { withRouter, Link } from 'react-router-dom';
import { parse, stringify } from 'query-string'
import assign from 'lodash.assign'

const WorkspaceItem = ({ location, workspace = {} }) => {

  const { _id, company } = workspace

  const { search } = location
  const query = parse(search)
  const { w } = query

  const newQuery = stringify(assign(query, { w: _id }))

  return (
    <Link
      to={{
        pathname: '/',
        search: newQuery
      }}
    >
      <Button className={_id === w ? 'btn-active' : ''}>
        {company}
      </Button>
    </Link>
  )
}

export default withRouter(WorkspaceItem)