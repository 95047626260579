/* eslint react-hooks/exhaustive-deps: 0*/
import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce';
import { getAccounts, getMovements } from '../../helpers/api';
import {
  InvoiceLayout,
  Spacer,
  DateSectionContainer,
  StyledFilterSelect
} from '../Dashboard/components/style/Components'
import DownloadMovementsButton from './DownloadMovementsButton';
import { FILTERS_INITIAL_STATE } from './utils/FILTERS_INITIAL_STATE';
import Filters from './components/Filters';
import MovementsTable from './components/MovementsTable';
import Pagination from './components/Pagination';
import { Heading } from '@pinv/ui';
import LoadingAllScreen from '../LoadingAllScreen';

const MOVEMENTS_LIMIT = 5000;

const Movements = ({ qs }) => {
  const [accounts, setAccounts] = useState([]);
  const [accountId, setAccountId] = useState();
  const [filters, setFilters] = React.useState(FILTERS_INITIAL_STATE);
  const [movements, setMovements] = useState([]);
  const [movementsToDownload, setMovementsToDownload] = useState([]);
  const [total, setTotal] = React.useState(0);
  const [emptyAccount, setEmptyAccount] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [fetchMovementsDebounced] = useDebouncedCallback((filters) => fetchMovements(filters), 600);

  //Quando cambia workspace viene eseguito il refetch degli accounts
  useEffect(()=>{
    const fetchBankAccounts = async () => {
      setLoading(true)
      const { data } = await getAccounts({ qs });
      setAccounts(data)
      setLoading(false)
    }

    fetchBankAccounts()
  }, [qs])

  useEffect(()=>{
    if (!accountId) return
    
    fetchMovements(filters)
  }, [accountId])

  const handleChangeSelect = async (e) => {
    const newAccountId = e.target.value
    setAccountId(newAccountId)
    if (newAccountId) {
      const { data } = await getMovements({ qs, accountId: newAccountId, limit: MOVEMENTS_LIMIT });
      setMovementsToDownload(data.data);
    }
  }

  const fetchMovements = async (filters) => {
    setLoading(true)
    const { data } = await getMovements({
      qs,
      accountId,
      ...filters,
    });
    const {data: movements, emptyAccount, total} = data;

    setMovements(movements);
    setTotal(Number(total));
    setEmptyAccount(emptyAccount);
    setLoading(false)
  };

  return (
    <>
      <LoadingAllScreen open={loading}/>
      <Spacer>
        <InvoiceLayout>
          <div>
            <Heading color="secondary">Conti e Movimenti</Heading>
          </div>
        </InvoiceLayout>
      </Spacer>
      <Spacer>
        <DateSectionContainer>
          <StyledFilterSelect
            value={accountId}
            onChange={handleChangeSelect}
          >
            <option value=''>Seleziona il conto</option>
            {accounts.map(({ accountId, labelName})=>(
              <option key={accountId} value={accountId}>{labelName}</option>
            ))}
          </StyledFilterSelect>
          {!accountId
            ? <p>Devi prima selezionare un conto per poter scaricare i movimenti</p>
            : <DownloadMovementsButton movements={movementsToDownload}/>
          }
        </DateSectionContainer>
      </Spacer>
        {accountId && 
        <>
          <Filters
            filters={filters}
            setFilters={setFilters}
            fetchMovements={fetchMovements}
            fetchListDebounced={fetchMovementsDebounced}
          />
          <Spacer>
          <MovementsTable movements={movements} emptyAccount={emptyAccount}/>
          {total > filters.limit && (<Pagination
              total={total}
              limit={filters.limit}
              offset={filters.offset}
              setOffset={(offset) => {
                if (offset === filters.offset) return;
                setFilters((prevFilters) => {
                  const newFilters = { ...prevFilters, offset };
                  fetchMovements(newFilters);
                  return newFilters;
                });
              }}
            />)}
            </Spacer>
        </>
        }
      
    </>
  );
};

export default Movements;