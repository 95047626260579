import React, { useCallback } from 'react'
import axios from 'axios'
import FileUpload from './FileUpload'

function upload({ file, apiUrl, _workspace, type }) {
  const { file: File, filename: NomeFile } = file
  const body = { File, NomeFile }

  return axios
    .post(apiUrl, body, { params: { _workspace, type } })
    .then(() => `File ${NomeFile} caricato con successo!`)
    .catch((err = {}) => {
      const {
        response: { data: { message: errorMessage = '' } = {} } = {},
      } = err

      const error = typeof errorMessage === 'string' ? `: ${errorMessage}` : ''

      return `File ${NomeFile} non caricato${error}`
    })
}

const FileUploadFn = ({ apiUrl, _workspace, type }) => {
  const onUpload = useCallback(
    async (file) => upload({ file, apiUrl, _workspace, type }),
    [apiUrl, _workspace, type],
  )

  return <FileUpload onUpload={onUpload} />
}

export default FileUploadFn
