import React from "react";
import { Button } from 'reactstrap';
import logo from '../images/Logo-pinv.png';
import { login } from "../helpers/api";
import { store, view } from 'react-easy-state';
import { ERROR_LOGIN } from "../helpers/strings";
import Loading from "../components/Loading";
import { Link } from 'react-router-dom';

const state = store({
  loading: false,
  error: ''
});

const Login = view(({ history }) => {

  const onSubmit = async (e) => {
    const values = {
      email: e.target[0].value,
      password: e.target[1].value
    }

    e.preventDefault();
    state.loading = true;
    state.error = '';
    
    try {
      await login(values);
      state.loading = false;
      history.replace('/');
    } catch (err) {
      console.log(err);
      state.loading = false;
      state.error = ERROR_LOGIN;
    }
  };

  return (
    <section>
      <div className="cont-100vh">
        <div className="container">
          <div className="cont-grey u-center-center">
            <img src={logo} className="u-center-horizontal" alt="logo" />

            <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                name="email"
                type="email"
                required
                className="form-control"
              />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  required
                  className="form-control"
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <Link to="/forgot">
                    <p className="u-text-right">
                      <i>Password dimenticata</i>
                    </p>
                  </Link>
                </div>
              </div>

              {
                state.loading ? <Loading /> :
                  <Button type="submit" color="primary" className="u-center-horizontal btn-pinv">
                    login
                  </Button>
              }

              {
                state.error &&
                <p className="form-response-error">
                  {state.error}
                </p>
              }
            </form>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Login;
