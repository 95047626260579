const variables = {
    primary: '#3F6DB5',
    base: '#2D3E51',
    danger: '#F34541',
    white: '#fff',
    black: '#222',
    lightgrey: '#F5F5F5',
    mediumgrey: '#C1C1C1',
    yellow: '#F8A326',
    purple: '#D852DE',
    green: '#00B372',
    grey: '#838587',
    lightgreen: 'rgba(0, 207, 210, 0.4);',
    tablegrey: '#ADADAD',
    tablelightgrey: 'rgba(220, 220, 220, 0.15);',
  };
  
  export default variables;
  