import styled from 'styled-components'
import variables from '../../Dashboard/components/style/variables'

export const DropzoneBox = styled.div`
  width: 100%;
  height: 200px;
  border: 2px dashed ${variables.base};
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
