import React from 'react'
import { Link } from 'react-router-dom'
import { getEmail } from '../helpers/storage';
import pkg from './../../package.json'

const FixNav = () => {
	const email = getEmail()

	return (
		<div>
			<nav>
				<p className="version-lg">V. {pkg.version}</p>
				<p>
					Ciao, <Link to="/profile">{email} <i className="fas fa-angle-right"></i></Link>
				</p>
			</nav>
		</div>
	)
}

export default FixNav