import React, { Component } from "react"
import { Button } from 'reactstrap'
import logo from '../images/Logo-pinv.png'
import { Link } from 'react-router-dom'
import { verify } from '../helpers/api'
import { ERROR_VERIFY } from "../helpers/strings";
import Loading from "../components/Loading";

class Verify extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      success: false,
      error: false
    }
  }
  componentDidMount() {
    const { token } = this.props.match.params
    verify(token)
      .then(() => this.setState({ success: true, loading: false }))
      .catch((err) => this.setState({ error: true, loading: false }))
  }

  render() {
    const { error, loading, success } = this.state

    return (
      <section>
        <div className="cont-100vh">
          <div className="container">
            <div className="cont-grey u-center-center">
              <img src={logo} className="u-center-horizontal" alt="" />

              {
                loading && <Loading />
              }
              {
                !loading && success &&
                <p>
                  La registrazione è stata effettuata con successo, <br />
                  prosegui per accedere al portale
                </p>
              }
              {
                !loading && error &&
                <p>{ERROR_VERIFY}</p>
              }

              <Link to="/login" replace={true}>
                <Button color="primary" className="u-center-horizontal btn-pinv">
                  accedi
                </Button>
              </Link>

            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Verify;
