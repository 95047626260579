import axios from "axios"
import { setToken, clearStorage, getToken, setEmail, setType } from "./storage";
import { COLLABORATOR_TYPES } from "./constants";

export const login = ({ email, password }) => {
  const {
    REACT_APP_AUTH_URL,
    REACT_APP_AUTH_APP_ID,
  } = process.env

  const LOGIN_URL = `${REACT_APP_AUTH_URL}/login?appId=${REACT_APP_AUTH_APP_ID}`

  return axios.post(LOGIN_URL, { email, password })
    .then(({ data = {} }) => {
      const { token } = data

      if (!token) {
        throw new Error('No token from login')
      }

      setToken(token)

      return me()
    })
    .then(({ data = {} }) => {
      const { email, type = COLLABORATOR_TYPES.COLLABORATOR } = data
      setEmail(email)
      setType(type)
    })
    .catch((err) => {
      clearStorage()
      throw err
    })
}

export const me = () => {
  const URL = `${process.env.REACT_APP_ACCOUNT_URL}/me`

  const token = getToken()
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
  return axios.get(URL, config)
}

export const getWorkspaces = async (params = {}) => {
  const { cf, company, vat } = params
  const URL = `${process.env.REACT_APP_ACCOUNT_URL}/workspaces`

  const token = getToken()
  const config = {
    params: {
      cf,
      company,
      vat
    },
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
  return await axios.get(URL, config);
}

export const getAccounts = async (params = {}) => {
  const { qs } = params
  const URL = `${process.env.REACT_APP_PINV_API}/accounts/bank/accounts?${qs}`

  const token = getToken()
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
  return await axios.get(URL, config);
}

export const getMovements = async (params = {}) => {
  const { qs, accountId, search, fromDate, toDate, offset, limit, sort, reconcilied, tipo } = params;
  const newParams = { accountId, search, from: fromDate, to: toDate, offset, limit, sort, reconcilied, tipo }

  const URL = `${process.env.REACT_APP_PINV_API}/accounts/bank/movements?${qs}`

  const token = getToken()
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    params: newParams
  }
  return await axios.get(URL, config);
}

export const getGenericInvoices = ({ type = 'invoices', _workspace, start, end }) => {
  const URL = `${process.env.REACT_APP_ACCOUNT_URL}/${type}`

  const token = getToken()
  const config = {
    params: {
      _workspace,
      start,
      end
    },
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
  return axios.get(URL, config)
}

export const pdf = ({ type = 'invoice', _workspace, _id }) => {
  const URL = `${process.env.REACT_APP_ACCOUNT_URL}/pdf`

  const token = getToken()
  const config = {
    params: {
      _workspace,
      _id,
      type
    },
    headers: {
      'Authorization': `Bearer ${token}`
    },
    responseType: 'blob'
  }
  return axios.get(URL, config)
}

export const csv = ({ type = 'invoice', _workspace, _id }) => {
  const URL = `${process.env.REACT_APP_ACCOUNT_URL}/csv`

  const token = getToken()
  const config = {
    params: {
      _workspace,
      _id,
      type
    },
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
  return axios.get(URL, config)
}

export const update = (_id, { company = '' }) => {
  const URL = `${process.env.REACT_APP_ACCOUNT_URL}/business-consultants/${_id}`

  const token = getToken()
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
  return axios.put(URL, { company }, config)
}

export const verify = (token) => {
  const {
    REACT_APP_AUTH_URL,
    REACT_APP_AUTH_APP_ID,
  } = process.env

  const URL = `${REACT_APP_AUTH_URL}/verify/${token}?appId=${REACT_APP_AUTH_APP_ID}`
  return axios.get(URL)
}

export const forgot = (email) => {
  const {
    REACT_APP_AUTH_URL,
    REACT_APP_AUTH_APP_ID,
  } = process.env

  const URL = `${REACT_APP_AUTH_URL}/forgot?appId=${REACT_APP_AUTH_APP_ID}`
  return axios.post(URL, { email })
}

export const reset = ({ password, token }) => {
  const {
    REACT_APP_AUTH_URL,
    REACT_APP_AUTH_APP_ID,
  } = process.env

  const URL = `${REACT_APP_AUTH_URL}/reset/${token}?appId=${REACT_APP_AUTH_APP_ID}`
  return axios.post(URL, { password })
}

export const xml = ({ type = 'invoice', _workspace, _id }) => {
  const URL = `${process.env.REACT_APP_ACCOUNT_URL}/xml`

  const token = getToken()
  const config = {
    params: {
      _workspace,
      _id,
      type
    },
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
  return axios.get(URL, config)
}