import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import Verify from './pages/Verify'
import Login from './pages/Login'
import Reset from './pages/Reset'
import Forgot from './pages/Forgot'
import NotFound from './pages/NotFound'
import PrivateRoute from './components/PrivateRoute'
import Layout from './pages/Layout'

const AppRouter = () => (
  <Router>
    <Switch>
      <Route path="/verify/:token" component={Verify} />
      <Route path="/login" component={Login} />
      <Route path="/reset/:token" component={Reset} />
      <Route path="/forgot" component={Forgot} />
      <PrivateRoute path="/" component={Layout} />
      <Route component={NotFound} />
    </Switch>
  </Router>
)

export default AppRouter
