const { Intl = {} } = window || {};
const LOCALE = 'IT';

export const formatCurrency = (amount = 0) => {
  if (!LOCALE || typeof Intl.NumberFormat !== 'function') {
    return amount;
  }

  return new Intl.NumberFormat(LOCALE, {
    style: 'currency',
    currency: 'EUR',
  }).format(amount);
};
