/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Spacer } from '../Dashboard/components/style/Components'
import Dropzone from './Dropzone'

const FileUpload = ({ onUpload }) => {
  const history = useHistory()
  const [files, setFiles] = useState([])
  const [responses, setResponses] = useState([])

  useEffect(() => {
    if (files.length > 0) {
      if (window.confirm(`Caricare e importare ${files.length} file?`)) {
        onConfirmUpload()
      } else {
        setFiles([])
      }
    }
    
  }, [files.length])

  const onReadFiles = useCallback((readFiles = []) => {
    setFiles(readFiles)
  }, [])

  const onConfirmUpload = useCallback(async () => {
    setResponses(['Caricamento in corso...'])

    const messages = await files.reduce(
      async (acc, file = {}) => {
        const results = await acc
        const { error } = file

        if (error) {
          return [...results, error]
        }

        const message = await onUpload(file)
        return [...results, message]
      },
      [onUpload],
    )

    setResponses(messages)
    setFiles([])
  }, [files])

  return (
    <Spacer>
      <button
        className="btn btn-pinv btn-back"
        onClick={history.goBack}
      >
        <i className="fas fa-arrow-left" /> indietro
      </button>

      <Dropzone onReadFiles={onReadFiles} />
      <hr />
      {Array.isArray(responses) &&
        responses.map((response) => <p>{response}</p>)}
    </Spacer>
  )
}

export default FileUpload
