import styled from 'styled-components'
import variables from './variables'

export const StyledInput = styled.input`
  margin-top: ${(props) => (props.nomargin ? '0' : '20px')};
  padding: 15px 15px;
  background-color: ${variables.lightgrey}!important;
  border: 1px solid ${variables.lightgrey}!important;
  width: ${(props) => (props.invoice ? '70% !important' : '100% !important')};
  height: 40px !important;
  border-radius: 5px !important;
  font-size: 14px;
  outline: none;
  box-sizing: border-box;
  text-align: ${(props) => (props.textcenter ? 'center' : 'left')};

  && input[type='number'],
  input[type='text'] {
    border-radius: 5px !important;
  }

  :focus {
    border: 1px solid ${variables.primary}!important;
  }
`

export const DateSectionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0;

  h3 {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
  }

  > :nth-child(2) {
    margin: 0 16px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-weight: bold;
  font-size: 14px;
  width: 30%;
  padding: 10px 32px;
  text-align: center;
  border: 1px solid ${variables.base};
  color: ${variables.base};
  background-color: ${variables.white};
  margin: 0 20px;
  svg {
    margin-right: 10px;
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    margin: 0;
    margin-right: ${({ margin }) => (margin === 'right' ? '10px' : '0')};
    margin-left: ${({ margin }) => (margin === 'left' ? '10px' : '0')};
    width: calc(50% - 10px);
  }

  @media (max-width: 420px) {
    width: calc(100% - 10px);
  }
`

export const TabsButtonContainer = styled.div`
  display: flex;
  max-width: 30%;
  > :first-child {
    margin-left: 0;
  }
  > * {
    :focus {
      background-color: ${variables.base};
      color: ${variables.white};
      outline: none;
    }
  }
`

export const MainButton = styled(StyledButton)`
  width: 50%;
  border: none;
  background-color: ${variables.primary};
  color: ${variables.white};
  margin-right: 0;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
  }
`

export const PlusIcon = styled.img`
  width: 15px;
  padding-right: 10px;
  vertical-align: middle;
`

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 0;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    padding-bottom: 0;
  }
`

export const FunctionButton = styled.div`
  width: 63%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;

    > :nth-child(3) {
      margin-top: 10px;
    }
  }

  @media (max-width: 420px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    > * {
      margin-bottom: 10px;
    }
  }
`

export const StyledFilterSelect = styled.select`
  background-color: ${variables.white};
  border: 1px solid ${variables.primary};
  font-size: 14px;
  outline: none;
  :focus {
    outline: none;
    border: 1px solid ${variables.primary};
    outline-offset: 0;
    box-shadow: none;
  }
`

export const StyledCash = styled.div`
  display: flex;
  padding: 20px 0;
  align-items: center;
  box-sizing: border-box;
  h3 {
    font-size: 16px;
    span {
      font-size: 24px;
      font-weight: 400;
      color: ${variables.primary};
      margin: 0 16px;
    }
  }

  * {
    user-select: none;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    > :last-child {
      margin-left: initial;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
`

export const Spacer = styled.div`
  padding: 20px 0;

  p {
    color: ${variables.grey};
  }

  .react-tabs {
    &__tab-list {
      margin: 0;
      color: ${variables.mediumgrey};
      font-size: 1.2rem;
      border: none;
    }

    &__tab-list > li:not(:first-child) {
      margin-left: 2rem;
    }

    &__tab  {
      padding: 1rem 0;
      background-color: #fbfbfb;

      &--selected {
        color: ${variables.primary};
        border: none;
      }
    }
  }

  .pagination {
    list-style-type: none;
    display: flex;
    justify-content: center;
    cursor: pointer;

    .selected {
      a {
        color: ${variables.primary};
      }
    }

    li {
      margin: 0 12px;
      font-size: 14px;
      a {
        color: ${variables.grey};
      }
    }
  }
`

export const InvoiceLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  a {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const Indicator = styled.div`
  height: 20px;
  background-color: ${(props) =>
    props.color === 'yellow'
      ? variables.yellow
      : props.color === 'red'
      ? variables.danger
      : props.color === 'purple'
      ? variables.purple
      : props.color === 'green'
      ? variables.green
      : props.color === 'grey'
      ? variables.grey
      : variables.white};
  border-radius: 10px;
  padding: 4px 16px;
  h6 {
    margin: 0;
    color: ${variables.white}!important;
    font-size: 12px;
  }
  white-space: nowrap;
`

export const IndicatorCont = styled.div`
  display: flex;
  align-items: center;

  > :first-child {
    margin-right: 20px;
  }

  @media (max-width: 1221px) {
    justify-content: center;
  }
`
