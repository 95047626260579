import React from 'react'

import NoClientsMessage from '../components/NoClientsMessage'
import HomeSection from '../components/Dashboard/components/HomeSection'
import Loading from '../components/Loading'
import { PinvDocumentale } from '@pinv/p-doc'
import Dashboard from '../components/Dashboard'

import { getToken, getType } from './../helpers/storage'
import { StyledButton, TabsButtonContainer } from '../components/Dashboard/components/style/Components'
import Movements from '../components/Movements'
import { COLLABORATOR_TYPES } from '../helpers/constants'
import DashboardHolding from '../components/Dashboard/DashboardHolding'
import { parse } from 'query-string'

const { REACT_APP_DOCUMENTAL_URL, REACT_APP_PINV_API } = process.env

class Home extends React.Component {
  state = {
    currentPage: 'cashflow',
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.workspaces.length === 0) {
      return true
    }
    return !nextProps.loading
  }

  render() {
    const { workspaces, loading, error, _workspace, location } = this.props
    const type = getType()
    const { search } = location
    const query = parse(search)
    const { w } = query

    if (loading) {
      return <Loading />
    }

    if (error) {
      return <p className="form-response-error">{error}</p>
    }

    if (workspaces && workspaces.length === 0) {
      return <NoClientsMessage />
    }

    if (w === 'all') {
      return <DashboardHolding token={getToken()} apiUrl={REACT_APP_PINV_API} />
    }

    if (!_workspace) {
      if (type !== COLLABORATOR_TYPES.HOLDING) {
        return <HomeSection />
      }
      return <DashboardHolding token={getToken()} apiUrl={REACT_APP_PINV_API} />
    }

    return (
      <React.Fragment>
        <TabsButtonContainer>
          <StyledButton onClick={() => this.setState({ currentPage: 'cashflow' })}>
            Cashflow
          </StyledButton>
          <StyledButton onClick={() => this.setState({ currentPage: 'documental' })}>
            Documentale
          </StyledButton>
          <StyledButton onClick={() => this.setState({ currentPage: 'movements' })}>
            Conti e movimenti
          </StyledButton>
        </TabsButtonContainer>

        {this.state.currentPage === 'cashflow' && (
          <Dashboard
            token={getToken()}
            apiUrl={REACT_APP_PINV_API}
            qs={`_workspace=${_workspace}`}
          />
        )}

        {this.state.currentPage === 'documental' && (
          <PinvDocumentale
            token={getToken()}
            apiUrl={REACT_APP_DOCUMENTAL_URL}
            qs={`_workspace=${_workspace}`}
          />
        )}

        {this.state.currentPage === 'movements' && (
          <Movements
            token={getToken()}
            apiUrl={REACT_APP_PINV_API}
            qs={`_workspace=${_workspace}`}
          />
        )}
      </React.Fragment>
    )
  }
}

export default Home
