import React from 'react';
import { StyledFilterSelect, StyledInput } from '../../Dashboard/components/style/Components';

const Filters = ({ filters, setFilters, fetchMovements, fetchListDebounced }) => {
  const onFilter = (filter) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, ...filter, offset: 0 };
      if (prevFilters.search !== newFilters.search) {
        fetchListDebounced(newFilters);
      } else {
        fetchMovements(newFilters);
      }
      return newFilters;
    });
  };

  return (
    <div className='filters-container'>
      <div style={{width: '500px'}}>
        <p>Ricerca</p>
        <StyledInput
          nomargin
          placeholder="cerca per descrizione o importo (l'importo deve essere esatto)"
          value={filters.search}
          onChange={({ target }) => onFilter({ search: target.value })}
        />
      </div>
      <div>
        <p>Da</p>
        <StyledInput
          type='date'
          nomargin
          value={filters.fromDate}
          onChange={({target}) => onFilter({ fromDate: target.value, offset: 0 })}
        />
      </div>
      <div>
        <p>A</p>
        <StyledInput
          type='date'
          label="A"
          nomargin
          value={filters.toDate}
          onChange={({target}) => onFilter({ toDate: target.value, offset: 0 })}
        />
      </div>
      <div>
        <p>Riconciliato</p>
        <StyledFilterSelect
          value={filters.reconcilied}
          onChange={(e) => onFilter({ reconcilied: e.target.value })}>
          <option value="">TUTTI</option>
          <option value={true}>SI</option>
          <option value={false}>NO</option>
        </StyledFilterSelect>
      </div>
      <div>
        <p>Importo</p>
        <StyledFilterSelect
          value={filters.tipo}
          onChange={(e) => onFilter({ tipo: e.target.value })}>
          <option value="">TUTTI</option>
          <option value="income">INCASSI</option>
          <option value="outcome">PAGAMENTI</option>
        </StyledFilterSelect>
      </div>
    </div>
  );
};

export default Filters;
