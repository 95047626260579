import React from 'react'
import styles from './style/HomeSection.module.css';
import arrowRight from '../../../images/arrow-right.svg'

const HomeSection = () => (
	<>
		<div className={styles.cardPrimary}>
			<h3 className={`${styles.heading} ${styles.headingSecondary}`}>Imposta il tuo account partendo dalla base</h3>
			<p className={styles.subHeading}>Ottieni il massimo da Pinv impostando al meglio il tuo account</p>
			<ol className={styles.list}>
				<li className={styles.listItem}>Verifica la tua mail</li>
				<li className={styles.listItem}>Invita il tuo primo cliente su Pinv</li>
				<li className={styles.listItem}>Visualizza il tuo primo cliente</li>
				<li className={styles.listItem}>Monitora l'andamento del tuo cliente</li>
				<li className={styles.listItem}>Visualizza i movimenti del tuo cliente</li>
			</ol>
		</div>
		<div className={styles.container}>
			<h3 className={`${styles.heading} ${styles.headingPrimary}`}>Documenti utili</h3>
			<h3 className={`${styles.heading} ${styles.headingSecondary}`}>Per te</h3>
			<div className={styles.grid}>
				<div className={styles.cardSecondary}>
					<h4 className={styles.headingSecondaryCard}>Guida alle agevolazioni fiscali</h4>
					<p className={styles.contentSecondaryCard}>La guida che riassume le agevolazioni fiscali</p>
					<a target="_blank" rel='noreferrer' href='https://pinv.it/wp-content/uploads/2022/08/Guida-alle-agevolazioni-fiscali-per-Startup-e-PMI-innovative-1.pdf' className={styles.linkSecondaryCard}>Scarica il documento<img alt='' src={arrowRight} /></a>
				</div>
				<div className={styles.cardSecondary}>
					<h4 className={styles.headingSecondaryCard}>Checklist delle attività</h4>
					<p className={styles.contentSecondaryCard}>Gestisci al meglio i tuoi clienti con Pinv</p>
					<a target="_blank" rel='noreferrer' href='https://pitch.com/v/gestione-clienti-con-pinv-e7n6tn' className={styles.linkSecondaryCard}>Scarica il documento<img alt='' src={arrowRight} /></a>
				</div>
				<div className={styles.cardSecondary}>
					<h4 className={styles.headingSecondaryCard}>I vantaggi di Pinv</h4>
					<p className={styles.contentSecondaryCard}>Scopri come Pinv può aiutarti nel tuo lavoro</p>
					<a target="_blank" rel='noreferrer' href='https://pitch.com/v/commercialisti-szhseq' className={styles.linkSecondaryCard}>Scarica il documento<img alt='' src={arrowRight} /></a>
				</div>
			</div>
			<h3 className={`${styles.heading} ${styles.headingSecondary}`}>Per i tuoi clienti</h3>
			<div className={styles.grid}>
				<div className={styles.cardSecondary}>
					<h4 className={styles.headingSecondaryCard}>Guida alle agevolazioni fiscali</h4>
					<p className={styles.contentSecondaryCard}>Semplifica la vita dei tuoi clienti con questa guida</p>
					<a target="_blank" rel='noreferrer' href='https://pinv.it/wp-content/uploads/2022/08/Guida-alle-agevolazioni-fiscali-per-Startup-e-PMI-innovative-1.pdf' className={styles.linkSecondaryCard}>Scarica il documento<img alt='' src={arrowRight} /></a>
				</div>
				<div className={styles.cardSecondary}>
					<h4 className={styles.headingSecondaryCard}>Pinv per la contabilità</h4>
					<p className={styles.contentSecondaryCard}>Perché utilizzare Pinv e come impostarlo? Scoprilo con la guida</p>
					<a target="_blank" rel='noreferrer' href='https://pitch.com/v/pmi-nnzrti' className={styles.linkSecondaryCard}>Scarica il documento<img alt='' src={arrowRight} /></a>
				</div>
				<div className={styles.cardSecondary}>
					<h4 className={styles.headingSecondaryCard}>Cosa è un flusso di cassa?</h4>
					<p className={styles.contentSecondaryCard}>Una guida per i tuoi clienti al flusso di cassa</p>
					<a target="_blank" rel='noreferrer' href='https://pinv.it/cashflow-cosa-e-flusso-di-cassa/' className={styles.linkSecondaryCard}>Scarica il documento<img alt='' src={arrowRight} /></a>
				</div>
			</div>
		</div>
	</>
)

export default HomeSection