import React from 'react';
import moment from 'moment';
import { formatCurrency } from '../helpers/currency';
import { ReactComponent as Income } from '../../../images/incassi-previsti.svg';
import { ReactComponent as Outcome } from '../../../images/pagamenti-previsti.svg';
import styles from './style/HomeSection.module.css';

const IvaCard = ({ iva = {}, isLoading = false }) => {
  const { date, value = 0 } = iva;

  return (
    <div className={styles.cardPrimary}>
      {date ? `Previsione versamento ${moment(iva?.date).format('DD/MM/YYYY')}` : 'Totale annuale'}
      <div className={styles.subject}>{value > 0 ? "Iva a debito" : "Iva a credito"}</div>
      <div className={styles.kpiContainer}>
        {value > 0 ? (
          <Outcome className={styles.outcomeSvg} />
        ) : (
          <Income className={styles.incomeSvg} />
        )}
        <div className={styles.kpiValue}>{isLoading ? '...' : formatCurrency(Math.abs(value))}</div>
      </div>
    </div>
  );
};
export default IvaCard;
