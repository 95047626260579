import React, { useState } from "react";
import { Button } from 'reactstrap';
import logo from '../images/Logo-pinv.png';
import { store, view } from 'react-easy-state';
import { reset } from "../helpers/api";
import { Link } from "react-router-dom";
import { ERROR_SERVER, SUCCESS_RESET } from "../helpers/strings";
import Loading from "../components/Loading";

const state = store({
  success: false,
  loading: false,
  error: ''
});

const Reset = view(({ match }) => {
  const { token } = match.params;
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    state.success = false;
    state.loading = true;
    state.error = '';

    try {
      await reset({ password, token });
      state.loading = false;
      state.success = true;
      setPassword('');
    } catch (err) {
      console.log(err);
      state.loading = false;
      state.error = ERROR_SERVER;
    }
  };

  return (
    <section>
      <div className="cont-100vh">
        <div className="container">
          <div className="cont-grey u-center-center">
            <img src={logo} className="u-center-horizontal" alt="logo" />

            <p>Reimposta la tua password</p>

            <form onSubmit={handleSubmit}>
              <div className="form-group">
              <label htmlFor="password">Nuova password</label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="form-control"
              /></div>
              <div className="row">
                <div className="col-12">
                  <Link to="/login" replace={true}>
                    <p className="u-text-right">
                      <i>Login</i>
                    </p>
                  </Link>
                </div>
              </div>
              {
                state.loading ? <Loading /> :
                  !state.success &&
                  <Button type="submit" color="primary" className="u-center-horizontal btn-pinv">
                    resetta
                  </Button>
              }
              
            </form>
            {
              state.success &&
              <div className="m-t-40">
                <p className="success">
                  {SUCCESS_RESET}
                </p>
                <Link to="/login" replace={true}>
                  <Button color="primary" className="u-center-horizontal btn-pinv">
                    login
                  </Button>
                </Link>
              </div>
            }
            {
              state.error &&
              <p className="form-response-error">
                {state.error}
              </p>
            }
          </div>
        </div>
      </div>
    </section>
  );
});

export default Reset;
