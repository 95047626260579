import React from 'react'
import moment from 'moment'
import { formatCurrency } from '../../Dashboard/helpers/currency';

const MovementsTable = ({ movements, emptyAccount }) => {

  if (emptyAccount) {
    return <div>Nessun movimento presente su questo conto</div>
  }
  
  return (
    <div className='mov-table-container'>
      <table className='mov-table'>
        <thead>
          <tr>
            <th>Data</th>
            <th>Descrizione</th>
            <th>Dare</th>
            <th>Avere</th>
            <th>Valuta</th>
            <th>Riconciliato</th>
          </tr>
        </thead>
        <tbody>
          {movements.length === 0 && (
            <tr><td colSpan={5} style={{textAlign: 'center'}}>Nessun movimento per questo range di date</td></tr>
          )}
          {movements.map(({_id, date, causale, amount, currency, reconcilied, tipo}) => (
            <tr key={_id}>
              <td>{moment(date).format('DD/MM/YYYY')}</td>
              <td>{causale}</td>
              <td>
                {tipo === 'outcome' ? <span className=" whitespace-nowrap">- {formatCurrency(amount)}</span> : formatCurrency(0) }
              </td>
              <td>
                {tipo === 'income' ? <span className=" whitespace-nowrap">+ {formatCurrency(amount)}</span> : formatCurrency(0) }
              </td>
              <td>{currency}</td>
              <td>{reconcilied ? 'Sì' : 'No'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MovementsTable