import React from 'react'
import { Button, InputGroup } from 'reactstrap'
import Loading from './Loading';
import WorkspaceItem from './WorkspaceItem';
import { withRouter, Link } from "react-router-dom"
import { DebounceInput } from 'react-debounce-input'
import pkg from './../../package.json'
import { isEqual } from 'lodash'
import { getType } from '../helpers/storage';
import { COLLABORATOR_TYPES } from '../helpers/constants';
import { parse } from 'query-string';

class Workspaces extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      cf: null,
      company: null,
      vat: null,
    }
  }

  handle(key, value) {
    if(value === '') {
      this.setState({ [key]: null })
    } else {
      this.setState({ [key]: value })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.state, prevState)) {
      this.props.loadWorkspaces(this.state)
    }
  }
  render() {
    const { loading, error, workspaces, location } = this.props
    const type = getType()
    const { search } = location
    const query = parse(search)
    const { w } = query

    return (
      <React.Fragment>
        <div className="filter-clients">
          <div className="version-mobile">V. {pkg.version}</div>
          <InputGroup>
            <DebounceInput
              onChange={(e) => this.handle('company', e.target.value)}
              debounceTimeout={300}
              className="form-control"
              placeholder="Ragione sociale"
            />
          </InputGroup>
          <InputGroup>
            <DebounceInput
              onChange={(e) => this.handle('vat', e.target.value)}
              debounceTimeout={300}
              className="form-control"
              placeholder="P.IVA"
            />
          </InputGroup>
          <InputGroup>
            <DebounceInput
              onChange={(e) => this.handle('cf', e.target.value)}
              debounceTimeout={300}
              className="form-control"
              placeholder="Codice Fiscale"
            />
          </InputGroup>
        </div>

        {
          loading && <Loading color="green" />
        }
        {
          error && <p className="form-response-error">{error}</p>
        }
        {
          Array.isArray(workspaces) && workspaces.length > 0 &&
            <div className="list-clients">
              {type === COLLABORATOR_TYPES.HOLDING && (
                <Link
                  to={{
                    pathname: '/',
                    search: 'w=all'
                  }}
                >
                  <Button className={w === undefined ? 'btn-active' : w === 'all' ? 'btn-active' : ''}>
                    Mostra tutti
                  </Button>
                </Link>
              )}
            {
              Array.isArray(workspaces) && workspaces.map(w => (
                <WorkspaceItem
                  key={w._id}
                  workspace={w}
                  onClick={(e) => e.target.value}
                />
              ))
            }
          </div>
        }

      </React.Fragment>
    )
  }
}


export default withRouter(Workspaces)