const TOKEN_KEY = 'pinv-account-token'
const EMAIL_KEY = 'pinv-account-email'
const TYPE_KEY = 'pinv-account-type'

export const setToken = (token) => {
  try {
    localStorage.setItem(TOKEN_KEY, token);
  } catch (e) {
    console.log(e)
  }
}

export const getToken = () => {
  try {
    return localStorage.getItem(TOKEN_KEY);
  } catch (e) {
    console.log(e)
    return null;
  }
}

export const clearStorage = () => {
  try {
    return localStorage.clear();
  } catch (e) {
    console.log(e)
  }
}

export const getEmail = () => {
  try {
    return localStorage.getItem(EMAIL_KEY);
  } catch (e) {
    console.log(e)
    return null;
  }
}

export const setEmail = (email) => {
  try {
    localStorage.setItem(EMAIL_KEY, email);
  } catch (e) {
    console.log(e)
  }
}

export const getType = () => {
  try {
    return localStorage.getItem(TYPE_KEY);
  } catch (e) {
    console.log(e)
    return null;
  }
}

export const setType = (type) => {
  try {
    localStorage.setItem(TYPE_KEY, type);
  } catch (e) {
    console.log(e)
  }
}