import React from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';

function Pagination({ total = 0, limit = 0, offset = 0, setOffset }) {
  const numberOfPages = Math.ceil(total / limit);
  const currentPage = offset / limit;

  return (
    <ReactPaginate
      initialPage={currentPage}
      onPageChange={({ selected }) => setOffset(selected * limit)}
      containerClassName="pinv_pagination"
      pageCount={numberOfPages}
      pageRangeDisplayed={4}
      marginPagesDisplayed={2}
      previousLabel="<"
      nextLabel=">"
    />
  );
}

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  setOffset: PropTypes.func.isRequired,
};

export default Pagination;
