import React, { useEffect, useState } from "react";
import { Button, Label } from 'reactstrap';
import { me, update } from "../helpers/api";
import { ERROR_SERVER } from "../helpers/strings";
import { Link } from "react-router-dom";
import { clearStorage } from "../helpers/storage";

const Loading = () => <div className="loading loading-profile"></div>

const Profile = ({history}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [profile, setProfile] = useState({})
  const { company } = profile || {};

  const onSubmit = async (e) => {
    e.preventDefault();

    const values = {
      company: e.target[0].value,
    }
    setLoading(true)
    setError('')

    try {
      const profileData = await update(profile._id, values);
      setLoading(false)
      setProfile(profileData);
    } catch (err) {
      console.log(err);
      setLoading(false)
      setError(ERROR_SERVER);
    }
  };

  const logout = () => {
    clearStorage();
    // Assuming you have the 'history' prop passed down to this component
    history.replace('/login');
  };

  useEffect(() => {
    setLoading(true)
    setError('')

    me()
      .then(({ data }) => {
        setLoading(false)
        setProfile(data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
        setError(ERROR_SERVER);
      });
  }, [profile._id]);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <Link to="/" replace={true}>
            <button className="btn btn-pinv btn-back">
              <i className="fas fa-arrow-left" /> home
            </button>
          </Link>
          <form onSubmit={onSubmit} className="input-office-name">
            <div className="mb-2 mr-sm-2 mb-sm-0 form-group flex">
            <Label htmlFor="company" className="mr-sm-2">
              <h4>Ciao{company ? ` ${company}` : ''}, </h4>
            </Label>
            <input
              type="text"
              name="company"
              id="company"
              placeholder="Inserisci qui il nome del tuo studio"
              required
              className="form-control"
            />
            {
              loading ? <Loading color="green" /> :
                <Button type="submit"><i className="fas fa-check"></i></Button>
            }
            </div>
          </form>
        </div>
      </div>
      {
        error &&
        <p className="form-response-error">{error}</p>
      }

      <div className="container m-t-40 u-text-center">
        <Button className="btn btn-pinv" onClick={logout}>
          logout
        </Button>
        <h4 className="m-t-40">
          Hai bisogno di assistenza?
        </h4>
        <br />
        <h5 className="text-action">
          scrivici
        </h5>
        <a href="mailto:help@pinv.it">
          <Button className="btn btn-pinv" style={{ textTransform: 'lowercase' }}>
            help@pinv.it
          </Button>
        </a>
      </div>
    </div>
  );
};

export default Profile;
