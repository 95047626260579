import React from 'react'

const Loading = ({ color = 'blue' }) => {
  const style = {
    borderTopColor: color === 'green' ? '#3F6DB5' : '#2b3e50',
    borderLeftColor: color === 'green' ? '#3F6DB5' : '#2b3e50',
  }

  return (
    <div className="loading-cont">
      <div className="loading" style={style} />
    </div>
  )
}

export default Loading
