/* eslint-disable */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { AiFillBank } from 'react-icons/ai';

import KpiCard from './components/KpiCard'
import IvaCard from './components/IvaCard'

import styles from './components/style/HomeSection.module.css';
import { formatCurrency } from './helpers/currency';

const Dashboard = ({ apiUrl, token }) => {
  const [kpis, setKpis] = useState({})
  const [year, setYear] = useState(new Date().getFullYear())
  const [isLoading, setIsLoading] = useState(false)

  const { balance = 0, metricsKpi = {} } = kpis;

  const {
    invoice,
    invoicein,
    toReceive = {},
    toPay = {},
    totalRevenue,
    totalExpense,
    income,
    outcome,
    iva = {},
  } = metricsKpi;

  useEffect(() => void getKPIData(), [year])

  const getKPIData = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get(
        `${apiUrl}/accounts/kpi/holding?year=${year}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      setKpis(data)
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }

  const onNextYearClick = () => {
    const currentYear = new Date().getFullYear();

    if (year < currentYear && !isLoading) {
      setYear(year + 1);
    }
  };

  const onPreviousYearClick = () => {
    if (year > 2015 && !isLoading) {
      setYear(year - 1);
    }
  };

  const getDate = () => {
    if (year === new Date().getFullYear()) {
      return new Date();
    } else {
      return new Date(year, 11, 31);
    }
  };

  return (
    <>
      <div className={styles.grid}>
        <div className={styles.cardPrimary}>
          <div className={styles.subject}>
            Saldo attuale
            <div className={styles.balanceContainer}>
              <AiFillBank size={'2em'} className={styles.bankIcon} />
              <div className={styles.balanceText}>
                {formatCurrency(balance)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.controlsTextContainer}>
        <p className={styles.controlsText}>Stai visualizzando i dati dell'anno</p>
        <div className={styles.controlsContainer}>
          <div className={styles.controlsArrow} onClick={onPreviousYearClick}>
            &#8249;
          </div>
          <div className={styles.controlsYear}>{year}</div>
          <div className={styles.controlsArrow} onClick={onNextYearClick}>
            &#8250;
          </div>
        </div>
      </div>
      <div className={styles.grid}>
        <KpiCard
          loading={isLoading}
          date={getDate()}
          incomingSubject="Fatture emesse (IVA esclusa)"
          incomingValue={invoice}
          outgoingSubject="Fatture ricevute (IVA esclusa)"
          outgoingValue={invoicein}
        />
        <KpiCard
          loading={isLoading}
          date={getDate()}
          incomingSubject="Da incassare (IVA inclusa)"
          incomingValue={toReceive.total}
          outgoingSubject="Da pagare (IVA inclusa)"
          outgoingValue={toPay.total}
        />
        <KpiCard
          loading={isLoading}
          date={getDate()}
          incomingSubject="Importi scaduti da incassare"
          incomingValue={toReceive.expired}
          outgoingSubject="Importi scaduti da pagare"
          outgoingValue={toPay.expired}
        />
        <KpiCard
          loading={isLoading}
          date={getDate()}
          incomingSubject="Totale ricavi"
          incomingValue={totalRevenue}
          outgoingSubject="Totale costi"
          outgoingValue={totalExpense}
        />
        <KpiCard
          loading={isLoading}
          date={getDate()}
          incomingSubject="Totale entrate"
          incomingValue={income}
          outgoingSubject="Totale uscite"
          outgoingValue={outcome}
        />
        <IvaCard iva={iva}/>
      </div>
    </>
  )
}

export default Dashboard
