import React from 'react'
import { stack as Menu } from 'react-burger-menu'
import Workspaces from './Workspaces'

import cross from '../images/cross.svg'
import menu from '../images/menu-icon.svg'

const NavMobile = (props) => (

  <div className="nav-responsive-show">
    <Menu
      width={'100%'}
      customBurgerIcon={<img src={menu} alt="menu-icon" />}
      customCrossIcon={<img src={cross} alt="cross-menu-open" />}
    >
      <div className="custom-padding">
        <Workspaces {...props} />
      </div>
    </Menu>
  </div>
)

export default NavMobile