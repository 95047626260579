import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { parse } from 'query-string'
import NavMobile from '../components/NavMobile'
import Workspaces from '../components/Workspaces'
import FixNav from '../components/FixNav'
import Home from './Home'
import { Route } from 'react-router-dom'
import Profile from './Profile'
import { getWorkspaces } from '../helpers/api'
import { ERROR_SERVER } from '../helpers/strings'
import Upload from '../components/FileUpload'

const { REACT_APP_PINV_API } = process.env

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      workspaces: [],
      loading: false,
      error: '',
    }
    this.loadWorkspaces = this.loadWorkspaces.bind(this)
  }

  async loadWorkspaces(params = {}) {
    this.setState({ loading: true })
    await getWorkspaces(params)
      .then(({ data }) =>
        this.setState({
          workspaces: data,
          loading: false,
        }),
      )
      .catch((err) => {
        this.setState({
          loading: false,
          error: ERROR_SERVER,
        })
        const { response = {} } = err || {}
        const { status = {} } = response || {}
        if (status === 401) {
          this.props.history.replace('/login')
        }
      })
  }

  componentDidMount() {
    this.loadWorkspaces()
  }

  render() {
    const { location } = this.props
    const { search } = location
    const query = parse(search)
    const { w } = query

    return (
      <div>
        <FixNav />

        <NavMobile {...this.state} loadWorkspaces={this.loadWorkspaces} replace/>

        <section className="section-navigation">
          <div className="lateral-navigation nav-resposive">
            <Workspaces {...this.state} loadWorkspaces={this.loadWorkspaces} />
          </div>

          <div className="corpo">
            <Route
              path="/"
              exact
              render={(props) => (
                <Home {...this.state} {...props} _workspace={w} />
              )}
            />
            <Route path="/profile" component={Profile} />
            <Route
              path="/upload-einvoice"
              render={(props) => (
                <Upload
                  apiUrl={`${REACT_APP_PINV_API}/accounts/einvoice/upload`}
                  type="TD01"
                  _workspace={w}
                  {...props}
                />
              )}
            />
            <Route
              path="/upload-einvoicein"
              render={(props) => (
                <Upload
                  apiUrl={`${REACT_APP_PINV_API}/accounts/einvoicein/upload`}
                  type="TD01"
                  _workspace={w}
                  {...props}
                />
              )}
            />
          </div>
        </section>
      </div>
    )
  }
}

export default withRouter(Layout)
