import React, { useState } from 'react';
import { Button } from 'reactstrap';
import logo from '../images/Logo-pinv.png';
import { store, view } from 'react-easy-state';
import { forgot } from "../helpers/api";
import { ERROR_SERVER, SUCCESS_FORGOT } from "../helpers/strings";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";

const state = store({
  success: false,
  loading: false,
  error: ''
});

const Forgot = view(() => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    state.loading = true;
    state.error = '';

    try {
      await forgot(email);
      state.loading = false;
      state.success = true;
      setEmail('');
    } catch (err) {
      console.log(err);
      state.loading = false;
      state.success = false;
      state.error = ERROR_SERVER;
    }
  };

  return (
    <section>
      <div className="cont-100vh">
        <div className="container">
          <div className="cont-grey u-center-center">
            <img src={logo} className="u-center-horizontal" alt="logo" />

            <p style={{ marginBottom: '50px' }}>
              Inserisci la tua email e ti verrà inviato un codice di verifica
            </p>

            <form onSubmit={handleSubmit}>
              <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="form-control"
              />
              </div>
              <div className="row">
                <div className="col-12">
                  <Link to="/login" replace={true}>
                    <p className="u-text-right">
                      <i>Login</i>
                    </p>
                  </Link>
                </div>
              </div>
              {
                state.loading ? <Loading /> :
                  <Button type="submit" color="primary" className="u-center-horizontal btn-pinv">
                    invia
                  </Button>
              }
            </form>

            {
              state.success &&
              <p className="m-t-40 success">{SUCCESS_FORGOT}</p>
            }
            {
              state.error &&
              <p className="form-response-error">
                {state.error}
              </p>
            }
          </div>
        </div>
      </div>
    </section>
  );
});

export default Forgot;
