import styled from 'styled-components'
import variables from '../../../Dashboard/components/style/variables'
import { CSVLink } from 'react-csv';

export const StyledDownloadCsvButton = styled(CSVLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-weight: bold;
  font-size: 14px;
  padding: 5px;
  padding: 10px 32px;
  text-align: center;
  border: 1px solid ${variables.base};
  color: ${variables.base};
  background-color: ${variables.white};

  &:hover {
    cursor: pointer;
    background-color: ${variables.base};
    color: ${variables.white};
    text-decoration: none;
  }

  &:focus {
    outline: none;
    color: ${variables.base};
  }

  &:active {
    background-color: ${variables.base};
    color: ${variables.white};
  }

  @media (max-width: 768px) {
    width: calc(50% - 10px);
  }

  @media (max-width: 420px) {
    width: calc(100% - 10px);
  }
`;