import moment from 'moment';

export const FILTERS_INITIAL_STATE = {
  fromDate: moment().subtract({ days: 90 }).format('YYYY-MM-DD'),
  toDate: moment().format('YYYY-MM-DD'),
  offset: 0,
  limit: 50,
  sort: 'date',
  order: 'desc',
};
